import {calculateTaxes} from "./taxes.helper";

export const calculateDelivery = (price, type, year, engineType, enginePower) => {
    const {pdv, toll, excise} = calculateTaxes(parseInt(price), type, parseInt(year), engineType, parseInt(enginePower));
    const results = {
        price: parseInt(price),
        seaDelivery: 1525,
        companyServices: 400,
        insurance: 0,
        storageUSA: 85,
        expedition: 550,
        broker: 200,
        loading: 50,
        deliveryToLviv: 650,
        pdv: Math.ceil(pdv),
        toll: Math.ceil(toll),
        excise: Math.ceil(excise),
    }

    const totalSum = Object.values(results).reduce((a, b) => a + b, 0);

    return {...results, totalSum};
}